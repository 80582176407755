import React from 'react';
import Grid from '@material-ui/core/Grid';
import ReactPlayer from 'react-player';
import { Link } from "react-router-dom";
import youtube from '../img/youtube_logo.png';
import instagram from '../img/instagram_logo.png';
import correo from '../img/correo_logo.png';
import importante from '../img/importante.png';
import google_forms from '../img/google_forms.png';
import vidca from '../img/vidca.jpg';
import fid from '../img/fid.jpg';
import universidad_logo from '../img/universidad_logo.png';
import logo_rea from '../img/logo_rea.png';
import './myStyles.css'
import banner_welc3 from '../img/banner_welc3.png'
  
  const Welcome = () => {
    return(
        <div className="container" >
            <img src={banner_welc3} alt="banner" width='870px' xs={12} sm={12} md={12}/>
            <Grid justifyContent='center' md={6} lg={6}>
                <div className='title'>
                    <h1 >Bienvenida</h1>
                </div>
            <p  className="pservices text-justify">
            Te damos la bienvenida a este espacio de colaboración y aprendizaje sobre prácticas inclusivas en el aula de inglés como lengua extranjera. Recorre nuestra página, déjanos tus preguntas o sugerencias, aprendamos juntos y acompañémonos en este proceso constante de transformación que es la inclusión en educación.
Te invitamos a ver este video para que conozcas a nuestro equipo y las motivaciones de este proyecto.

                </p>
            </Grid> 
            <Grid md={6} lg={6} container style={{ justifyContent: "center"} }>
                <ReactPlayer url='https://www.youtube.com/watch?v=-C7tGMzxefU' />
                    
            </Grid>
            <br></br>
            <div className='col-md-6 col-lg-6' style={{background:'#00b3c5', borderRadius:'10px'}} md={6} lg={6}>
            <br></br>
            </div>
            <br></br>
            <Grid xs={6} sm={6} md={6} lg={6}>
            <p  className="pservices text-justify">
            Puedes mantenerte en contacto con nosotros a través de las siguientes plataformas: 
                </p>
                <div className='image-container' xs={6} sm={6} md={6} lg={6}>
                    <a rel="license" href="https://www.instagram.com/inclusive_classroom_uach/"><img src={instagram} alt="encabezado" width='80px' /></a>
                    <a rel="license" href="https://www.youtube.com/channel/UC7CX6_dY5GPd0tG7bDOU3fA/featured"><img src={youtube} alt="encabezado" width='80px'/></a>
                    <Link to='/contact'>
                    <img src={correo} alt="encabezado" width='80px' xs={12} sm={12} md={12}/>
                    </Link>
                </div>
            </Grid>
            <br></br>
            <div className='col-md-6 col-lg-6' style={{background:'#00b3c5', borderRadius:'10px'}} md={6} lg={6}>
            <br></br>
            </div>
            <br></br>
            <Grid justifyContent='center' md={6} lg={6}>
            <p  className="pservices text-justify">
            Si te gustaría aportar a que esta comunidad de aprendizaje siga creciendo, escríbenos <Link to='/Contact' >aquí</Link> y nos pondremos en contacto contigo para que puedas colaborar con tu experiencia o recursos
                </p>

            </Grid>
            <br></br>
            <div className='col-md-6 col-lg-6' style={{background:'#00b3c5', borderRadius:'10px'}} md={6} lg={6}>
            <br></br>
            </div>
            <br></br>
            <Grid md={6} lg={6} justifyContent='center'>
            <Grid className='image-container'  xs={12} sm={12} md={12}>
            <img src={importante} alt="encabezado" width='80px' xs={12} sm={12} md={12}/>
            </Grid>
            
            <Grid ml={8} mr={8} >
            <p  className="pservices text-justify">
            Todo el material disponible en esta página es de uso libre y gratuito. Puedes compartir este material siempre y cuando cites la página y a nuestros colaboradores, no lo uses con fines comerciales y lo compartas bajo estas mismas condiciones. Haz click <a rel="license" href="http://creativecommons.org/licenses/by-nc-sa/4.0/"> aquí</a>. para conocer más sobre nuestra licencia Creative Commons. 
                </p>

            </Grid>
            </Grid>
            <br></br>
            <div className='col-md-6 col-lg-6' style={{background:'#00b3c5', borderRadius:'10px'}} md={6} lg={6}>
            <br></br>
            </div>
            <br></br>
            <Grid md={6} lg={6}>
                <div className='image-container' >
                    <a rel="license" href="https://docs.google.com/forms/d/e/1FAIpQLScZA8KRTe6efVXffr6lDR0ls5JE8aW7E67DLPlaYojNGFo43Q/viewform?usp=sf_link">
                        <img src={google_forms} width='50px' alt="encabezado" xs={12} sm={12} md={12} lg={12}/>
                    </a>
                </div>
                <Grid className="text" >
                    <div className='text image-container'>
                        <p className="text" >
                        Encuesta de satisfacción
                        </p>
                    </div>
                <p className="pservices text-justify" >
Por favor, responda las siguientes preguntas en relación a su satisfacción con los recursos educativos abiertos (REA) ofrecidos en la plataforma inclusiveclassroom.uach.cl. Sus respuestas son muy importantes para mejorar nuestra plataforma y ofrecer recursos que sean de utilidad para hacer sus clases de inglés más inclusivas. Esta encuesta le tomará aproximadamente 5 minutos.
                </p>
                </Grid>
            </Grid>
            <br></br>
            <div className='col-md-6 col-lg-6' style={{background:'#00b3c5', borderRadius:'10px'}}>
            <br></br>
            </div> 
            <br></br>
            <Grid xs={6} sm={6} md={6} lg={6}>
            <img src={vidca} alt="vidca" width='200px' xs={12} sm={12} md={12}/>
            <img src={fid} alt="fid" width='200px' xs={12} sm={12} md={12}/>
            <img src={universidad_logo} alt="universidad_logo" width='200px' xs={12} sm={12} md={12}/>
                </Grid> 
                <Grid xs={6} sm={6} md={6} lg={6}>
            <img src={logo_rea} alt="aula_inclusiva" width='200px' xs={12} sm={12} md={12}/>
                </Grid>   
        </div>

    );
}


export default Welcome;