import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import {grey} from '@material-ui/core/colors';
import encabezado from '../img/encabezado_sin_logo33.png';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import MenuVertical from './MenuVertical';
import './myStyles.css';  

export default function Buttons(){
        return(
            <div>
                <Grid className="position_banner" style={{background:grey[400]}}>
                  <img src={encabezado} alt="encabezado" height='105px' xs={12} sm={12} md={12}/>
                  <AppBar position="static" style={{background:grey[400],height:'40px',width:'884px'}}>
                  <Toolbar className="menuButton">
                    <Breadcrumbs className="separator" separator='|'>
                    <MenuVertical/>
                      <Button style={{color:'white', height:'25px'}} className="menuButton" href="https://docs.google.com/forms/d/e/1FAIpQLScZA8KRTe6efVXffr6lDR0ls5JE8aW7E67DLPlaYojNGFo43Q/viewform?usp=sf_link">
                      Encuesta
                      </Button>
                      <Link to='/Team' className="link">
                        <Button style={{color:'white', height:'25px'}} className="menuButton">
                        Equipo</Button></Link>
                      <Link to='/Contact' className="link" >
                        <Button style={{color:'white', height:'25px'}} className="menuButton">
                        Contacto</Button></Link>
                    </Breadcrumbs>

                    
                  </Toolbar>
                </AppBar>

                </Grid>
                
            </div>
        );
    }


