import menu1 from '../img/menu1.jpg';
import menu2 from '../img/menu2.png';
import menu3 from '../img/menu3.png';
import menu4 from '../img/menu4.png';
import menu5 from '../img/menu5.png';
import menu6 from '../img/menu6.png';
import menu7 from '../img/menu7.png';


const recursos = [
    {
        picture: menu2,
        theme: 'Experiencias con enfoque inclusivo ',
        url: '/resources'

    },
    {
        picture: menu1,
        theme: 'Food for Thought ',
        url: '/food'

    },
    {
        picture: menu5,
        theme: 'Enlaces de interés ',
        url: '/links'
    },
    {
        picture: menu4,
        theme: 'Lecturas sugeridas ',
        url: '/lectures'

    }, 
    {
        picture: menu3,
        theme: 'Recursos de apoyo ',
        url: '/supports'


    },
    {
        picture: menu6,
        theme: 'Investigaciones y tesis estudiantes UACh',
        url: '/menu'


    },
    {
        picture: menu7,
        theme: 'Material audiovisual estudiantes UACh',
        url: '/menu'


    }, 


]
export default recursos