import React, { Component }  from 'react';
import {grey} from '@material-ui/core/colors';
import logo from '../img/encabezado_no_fondo.png';
import './myStyles.css'


class Footer extends Component{
	render(){
		return(
            <footer className="App-footer" style={{background:grey[400]}}>
                <div className="footer">
                    <img alt='logo_uach' src={logo}/>
                    <p>
                    Proyecto Recursos Educativos Abiertos (REA): Una oportunidad de innovación y democratización del conocimiento sobre prácticas inclusivas en el aula de inglés.
                    </p>
                    <p >
                    VIDCA, 2020. Universidad Austral de Chile.
                    </p>                    
                    <div>
                        <a rel="license" href="http://creativecommons.org/licenses/by-nc-sa/4.0/"><img alt="Licencia Creative Commons" className="border-width:0" src="https://i.creativecommons.org/l/by-nc-sa/4.0/88x31.png" /></a><br />Esta obra está bajo una 
                        <a rel="license" href="http://creativecommons.org/licenses/by-nc-sa/4.0/">Licencia Creative Commons Atribución-NoComercial-CompartirIgual 4.0 Internacional</a>. 
                    </div>
                </div>
                
            </footer>			
			);
	}
}

export default Footer;