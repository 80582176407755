import React, {Component } from 'react';
import { BrowserRouter as Router} from "react-router-dom";
import {grey} from '@material-ui/core/colors';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import './myStyles.css'

class Buscador extends Component{
  

     myFunction = (e)  => {
        let palabra = document.getElementById('myInput').value;
        if (e.keyCode === 13){
          if (palabra.toLowerCase() === "felipe"){
            window.location.replace('/resources/1');
          }
          if (palabra.toLowerCase() === "felipe martinez"){
            window.location.replace('/resources/1');
          }
          if (palabra.toLowerCase() === "felipe martinez corona"){
            window.location.replace('/resources/1');
          }
          if (palabra.toLowerCase() === "estudiantes sordos"){
            window.location.replace('/resources/1');
          }
          if (palabra.toLowerCase() === "ana venegas millapan"){
            window.location.replace('/resources/2');
          }
          if (palabra.toLowerCase() === "ana venegas"){
            window.location.replace('/resources/2');
          }
          if (palabra.toLowerCase() === "ana"){
            window.location.replace('/resources/2');
          }
          if (palabra.toLowerCase() === "LGBTQ+"){
            window.location.replace('/resources/2');
          }
          if (palabra.toLowerCase() === "amalia ortiz de zarate"){
            window.location.replace('/resources/3');
          }
          if (palabra.toLowerCase() === "amalia ortiz"){
            window.location.replace('/resources/3');
          }
          if (palabra.toLowerCase() === "amalia"){
            window.location.replace('/resources/3');
          }
          if (palabra.toLowerCase() === "tecnicas teatrales"){
            window.location.replace('/resources/3');
          }
          if (palabra.toLowerCase() === "yasna yilorn barrientos"){
            window.location.replace('/resources/4');
          }
          if (palabra.toLowerCase() === "yasna yilorn"){
            window.location.replace('/resources/4');
          }
          if (palabra.toLowerCase() === "yasna"){
            window.location.replace('/resources/4');
          }
          if (palabra.toLowerCase() === "compromiso social"){
            window.location.replace('/resources/4');
          }
          if (palabra.toLowerCase() === "discapacidad visual"){
            window.location.replace('/resources/5');
          }
          if (palabra.toLowerCase() === "priscila ancheo"){
            window.location.replace('/resources/5');
          }
          if (palabra.toLowerCase() === "estudiantes con tea"){
            window.location.replace('/resources/6');
          }
          if (palabra.toLowerCase() === "paola castro"){
            window.location.replace('/resources/6');
          }
          if (palabra.toLowerCase() === "camila ojeda"){
            window.location.replace('/resources/6');
          }
        }
    }

    render(){
      return(
        <header>
          <Router>
            <Grid container className="header" style={{background:grey[400]}}> 
            <Breadcrumbs className="separador" separator='|' >
                      <Button style={{color:'white', height:'25px'}} href="http://uach.cl">
                      UACh
                      </Button>
                      <Button style={{color:'white', height:'25px'}} href="http://humanidades.uach.cl/">
                      Facultad de Filosofía y Humanidades
                      </Button>
                    </Breadcrumbs> 
              <div className="form-group col-md-2" sd={2} xs={2}> 
                <input type="text" id="myInput" onKeyUp={this.myFunction} placeholder="Buscar..."/>
              </div>
            </Grid>
          </Router>
        </header>
      );  
    };
}

export default Buscador;