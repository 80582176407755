import React, { Component } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import '../../components/myStyles.css'
import Buscador from '../../components/Buscador';
import Footer from '../../components/Footer';
import Menu from '../../components/Menu';
import Foods from '../../components/Foods'



class App extends Component {
  
    
  
    render(){
        return (
          <div>
            <Buscador/>
            <Menu/>
            <Foods/>                 
            <Footer/>
          </div>     
          );

    }
  }
  
export default App;