import React from 'react';
import './myStyles.css';
import { Link } from 'react-router-dom';
import logo from '../img/encabezado.png';
import ButtonGroup from '@material-ui/core/ButtonGroup';

const DropdownMenu = () => {
  return (
    <div className="dropdown-menu menu" style={{ backgroundColor: 'rgba(207,207,207, 0.8)'}}>
        <img src={logo} alt="logo"/>
        <div style={{display: 'flex'}} >
            <div className='navBar'>
                <ul style={{listStyleType: 'none'}}>
                    <ButtonGroup className="separator"
                    orientation="vertical"
                    color="white"
                    variant="text" 
                    size="small">
                    <li><Link to='/' className="link">Bienvenida</Link></li>
                    <li><Link to='/project' className="link" >Proyecto</Link></li>
                    <li><Link to='/menu' className="link-recursos">Recursos</Link></li>
                    <li><Link to='/Repository' className="link">Repositorio</Link></li>
                    <li><Link to='/News' className="link">Noticias</Link></li>

                    </ButtonGroup>
                    
                    
                    
                    
                </ul>
            </div>
        </div>
    </div>
  );
};

export default DropdownMenu;