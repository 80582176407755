import { BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import Contact from './pages/Contact';
import Food from './pages/Food';
import Lectures from './pages/Lectures';
import Links from './pages/Links';
import Menu from './pages/Menu';
import News from './pages/News';
import Project from './pages/Project';
import Repository from './pages/Repository';
import Resources from './pages/Resources';
import Resources1 from './pages/Resources1';
import Resources2 from './pages/Resources2';
import Resources3 from './pages/Resources3';
import Resources4 from './pages/Resources4';
import Resources5 from './pages/Resources5';
import Resources6 from './pages/Resources6';
import Supports from './pages/Supports';
import Team from './pages/Team';
import Welcome from './pages/Welcome';
import './App.css';

function App() {
  return(
  <Router>
          <Switch>
            <Route exact path={["/","/home"]}>
              <Welcome />
            </Route>
            <Route exact path="/project">
              <Project />
            </Route>
            <Route exact path="/team">
              <Team />
            </Route>
            <Route exact path="/resources">
              <Resources />
            </Route>
            <Route exact path="/repository">
              <Repository />
            </Route>
            <Route exact path="/contact">
              <Contact />
            </Route>
            <Route exact path="/resources/1">
              <Resources1 />
            </Route>
            <Route exact path="/resources/2">
              <Resources2 />
            </Route>
            <Route exact path="/resources/3">
              <Resources3 />
            </Route>
            <Route exact path="/resources/4">
              <Resources4 />
            </Route>
            <Route exact path="/resources/5">
              <Resources5 />
            </Route>
            <Route exact path="/resources/6">
              <Resources6 />
            </Route>
            <Route exact path="/news">
              <News />
            </Route>
            <Route exact path="/menu">
              <Menu />
            </Route>
            <Route exact path="/Food">
              <Food />
            </Route>
            <Route exact path="/links">
              <Links />
            </Route>
            <Route exact path="/supports">
              <Supports />
            </Route>
            <Route exact path="/lectures">
              <Lectures />
            </Route>
          </Switch>
    </Router>
    );
}

export default App;
